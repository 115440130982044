<template>
  <div class="used-in-articles-container">
    <div class="heading-text">
      {{ $t('article.usedInArticle') }}
    </div>
    <div
      class="alert alert-info"
      v-if="articles.length === 0"
    >
      {{ $t('article.notUsedInAnyArticle') }}
    </div>
    <div class="article-table" v-if="articles.length > 0">
      <div
        v-for="(article, index) in articles"
        :key="`item-${index}`"
        class="article-table__tr"
      >
        <div class="article-table__td title">
          {{ article.field.title }}
        </div>
        <div class="article-table__td date">
          {{ article.orderDate | prettyDateTime }}
        </div>
        <div class="article-table__td">
          <app-article-status :article="article" />
        </div>
        <div class="article-table__td">
          <ButtonTableAction icon="info" @click="infoButtonClick(article)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoreApi from '@/api/core'
import ArticleStatus from '@/components/article/ArticleStatus'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'

export default {
  name: 'UsedInArticlesTable',
  props: {
    id: {
      type: Number,
      required: true
    },
    /**
     * 'recipe' | 'galleryGroup' | 'quiz'
     */
    filter: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      articles: []
    }
  },
  components: {
    ButtonTableAction,
    appArticleStatus: ArticleStatus
  },
  methods: {
    getRecipeArticles () {
      CoreApi().get(`/article?view=minimal&filter_eq[${this.filter}]=${this.id}`)
        .then(response => {
          this.articles = response.data.data
          this.$emit('set-data', this.articles)
        })
        .catch(error => {
          console.log(error)
        })
    },
    infoButtonClick (article) {
      this.$router.push(`/article/${article.id}`)
    }
  },
  created () {
    this.getRecipeArticles()
  }
}
</script>

<style scoped lang="scss">
.heading-text {
  @include font(700 16px "Roboto");
  color: #465674;
  padding: 1rem;
  text-transform: uppercase;
}
.title {
  @include font(500 15px "Roboto");
  color: #465674;
}
.date {
  @include font(400 15px "Roboto");
  color: #8490A7;
}
.alert-info {
  margin-left: 1rem;
  margin-right: 1rem;
}
.used-in-articles-container {
  background: #fff;
  border-radius:  rem(6px);
  padding-bottom: rem(10px);
  border: solid #D1DBE4 rem(1px);
}
.article-table {
  width: 100%;
  padding: 0 rem(20px) 0 rem(20px);
  &__tr {
    display: grid;
    grid-gap: rem(20px);
    grid-template-columns: auto rem(120px) max-content max-content;
    padding: rem(14px) rem(14px) rem(14px) rem(14px);
    &:not(:last-child) {
      border-bottom: 1px solid #D1DBE4;
    }
  }
  &__td {
    display: flex;
    align-items: center;
  }
}
</style>
