<template>
    <span>
        <button
          v-show="selectedMedia.length"
          class="btn btn-sm btn-info"
          :class="{ 'hide': hideButton }"
          @click="showMediaEditModal"
        >
            <i class="fa fa-edit"></i> {{ $t('buttons.media_edit_button') }}
        </button>
        <app-media-edit-modal
          v-if="mediaEditModal"
          :selectedMedia="selectedMedia"
          @add-media="$emit('set-gallery-media', $event)"
          @close="closeMediaEditModal"
          :isGallery="isGallery"
          :site="site"
          :media-usage-type="mediaUsageType"
          :allow-gallery-creation="allowGalleryCreation"
          @set-gallery-group="setGalleryGroup"
          :insert-button-label="$t('modal.done')"
        >
        </app-media-edit-modal>
    </span>
</template>

<script>
import MediaEditModal from './MediaEditModal'

export default {
  name: 'MediaEditButton',
  props: {
    selectedMedia: {
      type: Array
    },
    isGallery: {
      type: Boolean,
      default: false
    },
    site: {
      type: [Number, Object],
      default: null
    },
    /**
     * See MediaUsageTypes.js
     */
    mediaUsageType: {
      type: [String, Object],
      default: null
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    allowGalleryCreation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mediaEditModal: false
    }
  },
  computed: {
    media () {
      return this.$store.getters['media/selectedGalleryMedia']
    }
  },
  components: {
    appMediaEditModal: MediaEditModal
  },
  methods: {
    showMediaEditModal () {
      this.mediaEditModal = true
    },
    closeMediaEditModal () {
      this.mediaEditModal = false
    },
    setGalleryGroup (id) {
      this.$emit('set-gallery-group', id)
    }
  }
}
</script>

<style lang="scss">
.image_dropzone {
  width: 100%;
  height: 172px;
  margin-bottom: 5px;
  border: 1px dashed #d9d9d9;
  line-height: 160px;
  background: #ced4da;
  cursor: pointer;
}
</style>
